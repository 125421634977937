<template>
  <v-row>
    <v-col
      v-for="dict in dictionaries"
      :key="dict.id"
      cols="6"
    >
      <v-card @click="openDictDialog(dict.name)">
        <v-card-text>
          {{ dict.name }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog"
              max-width="700px"
              persistent>
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-data-table
          fixed-header
          item-key="id"
          height="400px"
          :headers="headers"
          :items="employeesDict"
          :items-per-page="employeesDict.length"
          no-data-text="Brak danych"
          hide-default-footer
        >
        </v-data-table>
        <v-card-actions>
          <v-text-field
            v-model="employee.name"
            clearable
            dense
            outlined
            placeholder="Imię i nazwisko"
            style="padding: 30px 5px 5px 5px">
          </v-text-field>
          <v-text-field
            style="padding: 30px 5px 5px 5px"
            v-model="employee.email"
            clearable
            dense
            outlined
            placeholder="Email">
          </v-text-field>
          <v-btn color="primary" @click="addEmployee">
            Dodaj
          </v-btn>
          <v-btn
            color="error"
            outlined
            @click="closeDialog">
            Anuluj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DeviceService from "@/services/DeviceService";
export default {
  name: 'Dictionaries',
  data() {
    return {
      dialog: false,
      dialogTitle: '',
      employee: {},
      employeesDict: [],
      headers: [
        {text: 'Imię i nazwisko', value: 'name'},
        {text: 'Email', value: 'email'}
      ],
      dictionaries: [
        {id: 1, name: 'Pracownicy'},
        // {id: 2, name: 'Kategorie'},
        // {id: 3, name: 'Status'}
      ]
    }
  },
  computed: {
    ...mapState([
      'session'
    ])
  },
  methods: {
    ...mapActions([
      'getEmployees'
    ]),
    addEmployee() {
      DeviceService.addEmployee(this.employee).then(async () => {
        await this.getEmployees()
        this.employeesDict = this.session.employees
        this.employee = {}
      }).catch(e => {
        console.log(e)
      })
    },
    closeDialog() {
      this.dialog = false
      this.dialogTitle = ''
      this.employeesDict = []
    },
    async openDictDialog(title) {
      this.dialog = true
      this.dialogTitle = title
      await this.getEmployees()
      this.employeesDict = this.session.employees
    }
  }
}
</script>

<style scoped>

</style>
